import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import "../../locale/i18next";

import Notiflix from "notiflix";
import "../../utils/notiflixSchema";

import { getIDBRF, autoComplete, getKongToken } from "../../actions";
import { ROUTE_HOME, API_CAPTCHA } from "../../routes/";

import Heading from "../../components/heading";
import BaseInput from "../../components/input";
import Button from "../../components/button";

import ReCAPTCHA from "react-google-recaptcha";
import { API } from "../../actions/api";

const IdBRF = (props) => {
  const { t } = useTranslation();

  const SignupSchema = yup.object().shape({
    idbrf: yup.string().required(t("form.idBrf.idBrf.required")),
  });
  /* .max(6, t('form.idBrf.idBrf.maxDigits')) */
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, errors, register, setError } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  function error(message) {
    setError("idbrf", {
      type: "manual",
      message,
    });
  }

  const captchaRef = useRef(null);
  const onSubmit = async (data) => {
    const responseCaptcha = await captchaRef.current.executeAsync();
    captchaRef.current.reset();

    if (responseCaptcha) {
      Notiflix.Loading.Circle(t("loading.waiting"));
      const { message, success, token } = await getKongToken();
      if (success) {
        const res = await API.get(
          `${API_CAPTCHA}GetCaptcha_v2/${data.idbrf}?responseCaptcha=${responseCaptcha}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res.status === 200 && res.data) {
          if (res.data.errorMessage) {
            Notiflix.Loading.Remove();
            error(t("form.idBrf.idBrf.userNotFound.title"));
          } else {
            await getIDBRF(res.data, dispatch, history, token);
            Notiflix.Loading.Remove();
          }
        } else {
          Notiflix.Report.Failure(t(`Captcha inválido`), "", t("form.close"));
        }
      } else {
        Notiflix.Report.Failure(
          t<string>(message),
          "",
          t<string>("form.close")
        );
      }
      Notiflix.Loading.Remove();
    }
  };

  const backToHome = () => history.push(ROUTE_HOME);

  return (
    <div className="container -full_height">
      <Heading title={t("form.idBrf.heading")} />

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <ReCAPTCHA
          ref={captchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_SITEKEY}
        />
        <BaseInput
          name="idbrf"
          ref={register()}
          placeholder={t("form.idBrf.idBrf.placeholder")}
          container="form_group -mb_103"
          autoComplete={autoComplete}
          appearance="input"
          error={errors.idbrf?.message}
        />
        <Button
          type="button"
          appearance="button -cancel -margin15"
          container="form_group -grow0"
          label={t("form.cancel")}
          onClick={(e) => backToHome()}
        />

        <Button
          type="submit"
          appearance="button -submit -margin15"
          container="form_group -grow0"
          label={t("form.submit")}
        />
      </form>
    </div>
  );
};

export default IdBRF;
