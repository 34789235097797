import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../../locale/i18next";

import {
  API_RITM_STATUS,
  ROUTE_HOME,
  ROUTE_SUCCESS,
  ROUTE_ERROR,
} from "../../routes/";

import Heading from "../../components/heading/";
import Loading from "../../components/loading/";
import Button from "../../components/button/";

import { SET_STATUS_CACHE, getKongToken } from "../../actions";
import { API } from "../../actions/api";

interface RootState {
  ritmCode: string;
}

const selectState = (state: RootState) => state;

export default function ServiceNow() {
  const { t } = useTranslation();
  const history = useHistory();
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const url = `${API_RITM_STATUS}${state.ritmCode}`;

  const backToHome = useCallback(async () => {
    history.push(ROUTE_HOME);
  }, [history]);

  useEffect(() => {
    const axiosData = async () => {
      const { success, token } = await getKongToken();
      if (success) {
        const result = await API.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        dispatch({
          type: SET_STATUS_CACHE,
          payload: {
            status: result.data.status,
          },
        });
        let timeout: NodeJS.Timeout;
        switch (result.data.status) {
          case 0:
          case 2:
          case 5:
            clearInterval(timeout);
            history.push(`${ROUTE_ERROR}`);
            break;
          case 1:
            clearInterval(timeout);
            history.push(`${ROUTE_SUCCESS}`);
            break;
          case 3:
          default:
            timeout = setTimeout(() => {
              axiosData();
            }, 3000);
            break;
        }
      } else {
        history.push(`${ROUTE_ERROR}`);
      }
    };

    axiosData();
  }, [dispatch, history, url]);

  return (
    <div className="container -full_height -row">
      <Heading title={t<string>("servicenow.heading")} />

      <Loading text={t<string>("servicenow.loading")} modifiers="-mb132" />

      <Button
        type="button"
        appearance="button -cancel -red -margin15"
        container="form_group"
        label={t<string>("form.close")}
        onClick={(e) => backToHome()}
      />
    </div>
  );
}
