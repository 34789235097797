import './home.scss';
import './rtl.scss';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../../locale/i18next";

import { ROUTE_IDBRF, ROUTE_ACCESS } from "../../routes/";

export default function Home() {
	const { t } = useTranslation();
	//const link_ = window.location.href.includes("https://gente.brf.com/") ? ROUTE_ACCESS : "https://brfqas.service-now.com/services?id=category_international&catalog_id=eb3238961b8ac250f8e4a79fe54bcb75";
		const link_ = window.location.href.includes("https://gente.brf.com/") ? ROUTE_ACCESS : "https://hcm19.sapsf.com/login?company=brfsaP2";
return (

		<div className="home">
			<div className="home_choices -register">
				<div className="home_choices-content">
					<div className="home_choices-icon -register"></div>
					<div className="home_choices-label -title">{t<string>('home.choices.register.title')}</div>
					<Link to={ROUTE_IDBRF} className="link -yellow">
						{t<string>('home.choices.register.link')}
					</Link>
				</div>
			</div>

			<div className="home_choices -login">
				<div className="home_choices-content">
					<div className="home_choices-icon -login"></div>
					<div className="home_choices-label -title">{t<string>('home.choices.login.title')}</div>
					<Link
						to={{ pathname: link_ }}
						target="_parent"
						className="link -blue">
						{t<string>('home.choices.login.link')}
					</Link>
					<div className="home_choices-label -description"><div dangerouslySetInnerHTML={{ __html: t<string>('home.choices.login.description',) }} /></div>
				</div>
			</div>
		</div>

	)
}
