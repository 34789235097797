import React, { ButtonHTMLAttributes } from "react";
import './button.scss'
import './rtl.scss'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	appearance?: string;
	container?: string;
	label?: string;
	isDirty?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ appearance, label, container, isDirty, onClick, ...rest },ref) => {

	return (
		<div className={container}>
			<button
				{...rest}
				className={appearance}
				onClick={onClick}
			>
				{label}
			</button>
		</div>
	)
})

export default Button;
