import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import Notiflix from "notiflix";
import "../../utils/notiflixSchema";
import { formatCpf, formatDate } from "../../utils/masks";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import "../../locale/i18next";

import { ROUTE_HOME, ROUTE_PASSWORD } from "../../routes/";

import {
  getIDBRF,
  getUserInformation,
  autoComplete,
  checkToken,
  getKongToken,
} from "../../actions";

import Heading from "../../components/heading/";
import BaseInput from "../../components/input/";
import Button from "../../components/button/";

interface RootState {
  employeeId: string;
  name: string;
  docNumber: string;
  birthDate: Date;
  startDate: Date;
  mothersName: string;
  country: string;
  ritmCode: string;
  newPassword: string;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const selectState = (state: RootState) => state;

export default function Profile() {
  const token = useQuery().get("token");
  const state = useSelector(selectState);
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const history = useHistory();

  const formRef = useRef(null);

  useEffect(() => {
    if (token) {
      const reqToken = async () => {
        try {
          Notiflix.Loading.Circle(t("loading.waiting"));
          const { token: kongToken } = await getKongToken();

          const data = await checkToken(token, dispatch, kongToken);
          Notiflix.Loading.Remove();
          if (data) {
            formRef.current.querySelector(`[name=employeeId]`).value = data.id;
          } else {
            history.push("/");
          }
        } catch (e) {
          Notiflix.Loading.Remove();
          history.push("/");
        }
      };
      reqToken();
    }
  }, []);

  const shapeInit = {
    employeeId: yup.string().required(t("form.idBrf.idBrf.required")),
    birthDate: yup
      .string()
      .required(t("form.profile.birthDate.required"))
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
        t("form.profile.birthDate.invalid")
      ),
    startDate: yup
      .string()
      .required(t("form.profile.startDate.required"))
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
        t("form.profile.startDate.invalid")
      ),
  };

  const shapeDocNumber = {
    docNumber: yup
      .string()
      .required(t("form.profile.docNumber.required"))
      .matches(
        /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
        t("form.profile.docNumber.invalid")
      ),
  };

  const SignupSchema = yup.object().shape(
    state.country === "Brazil"
      ? {
          ...shapeInit,
          ...shapeDocNumber,
        }
      : shapeInit
  );

  function error(id, message) {
    setError(id, {
      type: "manual",
      message,
    });
  }

  const { register, handleSubmit, errors, setError, unregister } = useForm({
    defaultValues: {
      employeeId: state.employeeId,
      docNumber: "",
      name: state.name,
      birthDate: "",
      startDate: "",
      mothersName: state.mothersName,
      country: state.country,
    },
    resolver: yupResolver(SignupSchema),
  });

  if (state.country !== "1") unregister("docNumber");

  const onSubmit = useCallback(
    async (data) => {
      Notiflix.Loading.Circle(t("loading.waiting"));
      const { message, success, token } = await getKongToken();
      if (success) {
        if (state.name) data.name = state.name; //include in set dispatch
        const response = await getUserInformation(
          data,
          dispatch,
          history,
          state,
          token
        );
        Notiflix.Loading.Remove();

        if (response && response.data.errorMessage) {
          Notiflix.Report.Failure(
            t(`${response.data.errorMessage}`),
            "",
            t("form.close")
          );
        } else {
          history.push(ROUTE_PASSWORD);
        }
      } else {
        Notiflix.Report.Failure(
          t<string>(message),
          "",
          t<string>("form.close")
        );
      }
      Notiflix.Loading.Remove();
    },
    [dispatch, history, t, state]
  );

  const backToHome = () => history.push(ROUTE_HOME);

  // const changeID = useCallback(async (e) => {
  // 	if (!e) return false;

  // 	Notiflix.Loading.Circle(t("loading.waiting"));
  // 	const response = await getIDBRF(e, dispatch, history);

  // 	if (response && response.data.errorMessage) {
  // 		error("employeeId", t('form.idBrf.idBrf.userNotFound.title'));
  // 	}

  // 	Notiflix.Loading.Remove();
  // }, [dispatch, history, t]);

  return (
    <div className="container -full_height">
      <Heading title={t("form.profile.heading", { name: state.name })} />

      <form className="form" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <BaseInput
          name="employeeId"
          ref={register()}
          placeholder={t("form.idBrf.idBrf.placeholder")}
          container="form_group"
          disabled
          appearance="input"
          autoComplete={autoComplete}
          error={errors.employeeId?.message}
          // onBlur={(e) => changeID(e.currentTarget.value)}
        />

        {state.country === "1" && (
          <BaseInput
            name="docNumber"
            maxLength={14}
            ref={register()}
            placeholder={t("form.profile.docNumber.placeholder")}
            container="form_group"
            appearance="input"
            autoComplete={autoComplete}
            error={errors.docNumber?.message}
            onChange={(e) => {
              e.currentTarget.value = formatCpf(e.currentTarget.value);
            }}
          />
        )}

        <BaseInput
          name="birthDate"
          maxLength={10}
          ref={register()}
          placeholder={t("form.profile.birthDate.placeholder")}
          container="form_group"
          appearance="input"
          autoComplete={autoComplete}
          error={errors.birthDate?.message}
          onChange={(e) =>
            (e.currentTarget.value = formatDate(e.currentTarget.value))
          }
          isDate
          language={language}
        />
        <BaseInput
          name="startDate"
          maxLength={10}
          ref={register()}
          placeholder={t("form.profile.startDate.placeholder")}
          container="form_group -mb_103"
          appearance="input"
          autoComplete={autoComplete}
          error={errors.startDate?.message}
          onChange={(e) =>
            (e.currentTarget.value = formatDate(e.currentTarget.value))
          }
          isDate
          language={language}
        />

        <Button
          type="button"
          appearance="button -cancel -margin15"
          container="form_group -grow0"
          label={t("form.cancel")}
          onClick={(e) => backToHome()}
        />
        <Button
          type="submit"
          appearance="button -submit -margin15"
          container="form_group -grow0"
          label={t("form.submit")}
        />
      </form>
    </div>
  );
}
