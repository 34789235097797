import { convertDate } from "../utils/masks";

import {
  API_CACHE,
  API_USERS_TOKEN,
  API_UNLOCK,
  API_PASSWORD,
  API_URL_TOKEN,
  kongConfig,
} from "../routes/";

import {
  ROUTE_WAITING,
  ROUTE_PROFILE,
  ROUTE_SUCCESS,
  ROUTE_ERROR,
} from "../routes/";
import { API } from "./api";

export const SET_IDBRF = "SET_IDBRF";
export const SET_INFORMATIONS = "SET_INFORMATIONS";
export const SET_RITMCODE = "SET_RITMCODE";
export const GET_SERVICENOW = "GET_SERVICENOW";
export const SET_STATUS = "SET_STATUS";
export const SET_STATUS_CACHE = "SET_STATUS_CACHE";

export const autoComplete = "on";

interface DataGetUserProps {
  employeeId: string;
  name: string;
  docNumber: string;
  birthDate: Date;
  startDate: Date;
}

export const getIDBRF = async (data, dispatch, history, kongToken) => {
  try {
    const [verifyCache] = await Promise.all([
      API.get(`${API_CACHE}${data.id}`, {
        headers: {
          Authorization: `Bearer ${kongToken}`,
        },
      }),
    ]);

    if (verifyCache && verifyCache.data !== "") {
      dispatch({
        type: SET_RITMCODE,
        payload: {
          employeeId: data.id,
          name: data.name,
          ritmCode: verifyCache.data,
        },
      });
      history.push(`${ROUTE_WAITING}`);
      return verifyCache;
    } else {
      dispatch({
        type: SET_IDBRF,
        payload: {
          employeeId: data.id,
          name: data.name,
          country: data.country,
        },
      });

      history.push(ROUTE_PROFILE);
      return data;
    }
  } catch (error) {
    console.error(`error fetching data from api. error;`, error);
  }
};

export const getUserInformation = async (
  { employeeId, docNumber, startDate, birthDate, name }: DataGetUserProps,
  dispatch,
  history,
  state,
  token
) => {
  try {
    const userInformation = {
      employeeId,
      docNumber,
      startDate: convertDate(startDate),
      birthDate: convertDate(birthDate),
      country: state.country,
      mothersName: state.mothersName,
      name: state.name,
    };

    const verifyUserInformation = await API.post(
      `${API_UNLOCK}`,
      userInformation,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    userInformation.mothersName = verifyUserInformation.data.mothersName;

    dispatch({
      type: SET_INFORMATIONS,
      payload: userInformation,
    });
    dispatch({
      type: SET_RITMCODE,
      payload: {
        employeeId,
        ritmCode: verifyUserInformation.data.ritmCode,
        name: verifyUserInformation.data.name,
      },
    });
    return verifyUserInformation;
  } catch (error) {
    console.error(`error fetching data from api. error;`, error);
  }
};

interface getPasswordProps {
  newPassword: string;
  ritmCode: string;
  employeeId: string;
  country: string;
  docNumber: string;
  startDate: Date;
  birthDate: Date;
  name: string;
  token?: string;
  kongToken: string;
}

export const getPassword = async (
  {
    newPassword,
    ritmCode,
    employeeId,
    country,
    docNumber,
    startDate,
    birthDate,
    name,
    token = "",
    kongToken = "",
  }: getPasswordProps,
  dispatch,
  history
) => {
  try {
    const headers = { Authorization: `Bearer ${kongToken}` };

    const hasAiraToken = token?.length > 0;
    const source = hasAiraToken ? "sales" : "hrportal";

    const response = await API.post(
      API_PASSWORD,
      {
        newPassword,
        ritmCode,
        employeeId,
        country,
        docNumber,
        startDate,
        birthDate,
        token,
        source,
      },
      { headers }
    );
    if (response.data.errorMessage) return response;

    dispatch({
      type: SET_STATUS,
      payload: {
        employeeId,
        name,
        ritmCode: response.data.ritmCode,
        country,
        status: response.data.status,
      },
    });
    const { status } = response.data;
    switch (status) {
      case 0:
      case 2:
        history.push(`${ROUTE_ERROR}`);
        break;
      case 1:
        history.push(`${ROUTE_SUCCESS}`);
        break;
      default:
        history.push(`${ROUTE_WAITING}`);
        break;
    }
    return response;
  } catch (error) {
    console.error(`error fetching data from api. error;`, error);
  }
};

export const checkToken = async (
  token: string,
  dispatch,
  kongToken: string
) => {
  try {
    const { data } = await API.post(
      API_USERS_TOKEN,
      {
        token,
      },
      { headers: { Authorization: `Bearer ${kongToken}` } }
    );
    dispatch({
      type: SET_INFORMATIONS,
      payload: {
        employeeId: data.id,
        name: data.name,
        mothersName: data.mothersName,
        country: data.country,
        token: token,
      },
    });

    return data;
  } catch (e) {
    return null;
  }
};

export const getKongToken = async () => {
  try {
    const { data, status } = await API.post(
      API_URL_TOKEN,
      {},
      { headers: kongConfig }
    );
    return { message: null, success: status === 200, token: data.token };
  } catch (e) {
    const { data, status } = e.response;
    return { message: data.message, success: false, token: null };
  }
};
