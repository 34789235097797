export const apiKey = process.env.REACT_APP_API_KEY;
export const kongConfig = {
  client_id: process.env.REACT_APP_API_CLIENT_ID,
  iss: process.env.REACT_APP_API_ISS,
  secret: process.env.REACT_APP_API_SECRET,
};

export const ROUTE_HOME = "./";
export const ROUTE_IDBRF = "/id-brf";
export const ROUTE_PROFILE = "./seus-dados";
export const ROUTE_PASSWORD = "./registrar-senha";

export const ROUTE_ACCESS = process.env.REACT_APP_ROUTE_ACCESS;

export const ROUTE_WAITING = "./aguarde";
export const ROUTE_SUCCESS = "/sucesso";
export const ROUTE_ERROR = "/erro";

export const API_CACHE = `cache/`;
export const API_RITM_STATUS = `checkritmstatus/`;
export const API_USERS = `Users/`;
export const API_USERS_TOKEN = `Users/Token`;
export const API_UNLOCK = `unlock/`;
export const API_PASSWORD = `changepassword/`;
export const API_CAPTCHA = `captcha/`;
export const API_URL_TOKEN = process.env.REACT_APP_API_URL_TOKEN;
