import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	pt: {
		translation: {
			home: {
				choices: {
					register: {
						title: "NÃO POSSUI E-MAIL BRF?",
						/* description:
							"Cadastre uma senha para poder acessar o Portal de Gente.",
						obs: "* Cadastro é apenas para colaboradores que não possuem e-mail BRF. Caso possua e-mail, utilizar os demais canais de atendimento", */
						link: "CADASTRAR OU ALTERAR SUA SENHA",
					},
					login: {
						title: "JÁ CADASTROU SUA SENHA, OU JÁ POSSUI E-MAIL BRF?",/*
						description:
							"<strong>Importante:</strong> Clique em acessar, caso não tenha e-mail BRF, preencha com SEUID@brf.com, da seguinte forma: <br><strong>E-mail:</strong> SEUID@brf.com <br><strong>Senha:</strong> sua senha cadastrada, e pronto!", */
						description:
							"<strong>Informação para os colaboradores sem e-mail BRF: </strong><br>Caso não tenha e-mail BRF, na próxima tela preencha o campo de e-mail da seguinte forma: <br><br><b>E-mail:</b> SEUID@brf.com",
						link: "ENTRAR",
					},
				},
			},
			form: {
				idBrf: {
					heading: "QUAL O SEU ID BRF?",
					idBrf: {
						label: "Qual seu ID BRF?",
						placeholder: "ID BRF",
						required: "Digite seu ID BRF",
						maxDigits: "Seu ID deve conter no máximo 6 dígitos",
						lenght: {
							title: "ID deve conter no máximo 6 dígitos",
						},
						userNotFound: {
							title: "ID BRF Incorreto ou inexistente",
						},
					},
				},
				profile: {
					heading:
						"Precisamos validar algumas informações",
					docNumber: {
						placeholder: "CPF",
						required: "Digite seu CPF",
						invalid: "CPF inválido",
					},
					birthDate: {
						placeholder: "Data de nascimento (DD/MM/YYYY)",
						required: "Digite sua Data de nascimento",
						invalid: "Data de nascimento inválida",
					},
					startDate: {
						placeholder: "Data de admissão (DD/MM/YYYY)",
						required: "Digite sua Data de admissão",
						invalid: "Data de admissão inválida",
					},
					errorMessage: "Dados inválidos, verifique os campos e tente novamente.",
					// errorMessage: {
					// 	userNotFound: "ID BRF Incorreto ou inexistente",
					// 	maxDigits: "Seu ID deve conter no máximo 6 dígitos",
					// 	docNumber: "CPF inválido",
					// 	startDate: "Data de admissão não confere",
					// 	birthDate: "Data de nascimento não confere",
					// },
				},
				password: {
					heading: "Digite sua senha e guarde com segurança!",
					password: {
						placeholder: "**************************",
						required: "Sua senha precisa respeitar as regras",
					},
					rules: {
						heading: "Sua senha precisa respeitar as seguintes regras:",
						minLen: "10 ou mais caracteres",
						uppercase: "Uma ou mais letras maiúsculas",
						lowercase: "Uma ou mais letras minúsculas",
						number: "Um ou mais números",
						special: "Um ou mais caracteres especiais",
						mother: "Não pode conter o nome de sua mãe",
						name: "Não usar seu nome ou sobrenome",
						idbrf: "Não usar o seu ID BRF",
						older: "Não repita senhas anteriores",
						commonWords: "Evite palavras do cotidiano de trabalho como: BRF, Senha, Sadia, Perdigão",
						attention: "Atenção",
					},
					errorMessage: {
						misMatch: "A senha não respeita as regras mínimas exigidas.",
						api: "Ocorreu um erro em nosso sistema, tente novamente!",
						computerized: "Cadastro de senha é funcionalidade exclusiva para colaboradores sem e-mail BRF.",
					},
				},
				cancel: "Cancelar",
				submit: "Continuar",
				send: "ENVIAR",
				access: "Acessar",
				register: "CADASTRAR NOVA SENHA",
				close: "Fechar",
			},
			servicenow: {
				heading: "Aguarde, sua senha esta sendo verificada e cadastrada.",
				loading:
					"Colaborador, este cadastro pode levar alguns minutos, agradecemos sua paciência.",

				states: {
					success: {
						heading: "<strong>{{name}}</strong>, sua senha foi<br>cadastrada!",
						text:
							"<strong>Importante:</strong> Clique em acessar, caso não tenha e-mail BRF, preencha com SEUID@brf.com, da seguinte forma: <br><strong>E-mail:</strong> SEUID@brf.com <br><strong>Senha:</strong> sua senha cadastrada, e pronto!",
					},
					error: {
						heading:
							"Erro no cadastro!",
						text:
							"Tivemos problemas para realizar o cadastro, pedimos desculpas pela inconveniência. Por favor tente novamente.<br><br><strong>Fique atento</strong> e não cadastre uma senha já utilizada anteriormente.",
					},
					passwordError: {
						heading: "Sua senha não está de acordo com as regras de segurança.",
						text: "Tente cadastrar novamente seguindo os pontos de atenção:<br><ul><li>Não repita senhas anteriores;</li><li>Evite palavras do cotidiano do trabalho, como: BRF, senha, Sadia, Perdigão.</li></ul>"
					}
				},
			},
			loading: {
				waiting: "Buscando dados, aguarde...",
			},
		},
	},
	en: {
		translation: {
			home: {
				choices: {
					register: {
						title: "DON'T HAVE BRF EMAIL?",
						/* description: "Register a password to be able to access the People Portal.",
						obs: "* Registration is only for employees who do not have a BRF email. If you have e-mail, use the other service channels",*/
						link: "REGISTER OR CHANGE YOUR PASSWORD",
					},
					login: {
						title: "HAVE YOU ALREADY REGISTERED YOUR PASSWORD, OR DO YOU ALREADY HAVE BRF EMAIL?",
						/* description:
							"<strong> Important:</strong> Click on access, if you don't have a BRF e-mail, fill in with YOURID@brf.com, as follows: <br> <strong> E-mail: </strong> YOURID@brf.com <br> <strong> Password: </strong> your registered password, and you're done!", */
						description:
							"<strong>Information for employees without BRF email: </strong><br>If you do not have a BRF email, in the next screen fill in the email field as follows: <br><br><b>E-mail:</b> YOURID@brf.com",
						link: "LOG IN",
					},
				},
			},
			form: {
				idBrf: {
					heading: "WHAT'S YOUR ID BRF?",
					idBrf: {
						label: "Qual seu ID BRF?",
						placeholder: "ID BRF",
						required: "Enter your ID BRF",
						maxDigits: "Your ID must contain at máximo 6 digits",
					},
				},
				profile: {
					heading:
						"We need to validate some information",
					docNumber: {
						placeholder: "CPF",
						required: "Enter your CPF",
						invalid: "Invalid Social Security Number",
					},
					birthDate: {
						placeholder: "Date of birth (DD/MM/YYYY)",
						required: "Enter your birth date",
						invalid: "Invalid date of birth",
					},
					startDate: {
						placeholder: "Date of hire (DD/MM/YYYY)",
						required: "Enter your startDate Date",
						invalid: "Invalid admission date",
					},
					errorMessage: "Invalid data, please check fields and try again.",
					// errorMessage: {
					// 	userNotFound: "Incorrect or nonexistent BRF ID",
					// 	maxDigits: "Your ID must contain a maximum of 6 digits",
					// 	docNumber: "Invalid Social Security Number",
					// 	startDate: "Admission date does not match",
					// 	birthDate: "Date of birth does not match",
					// },
				},
				password: {
					heading: "Enter your password and save it safely!",
					password: {
						placeholder: "**************************",
						required: "Your password must comply with the rules",
					},
					rules: {
						heading: "Your password must comply with the following rules:",
						minLen: "10 or more characters",
						uppercase: "One or more capital letters",
						lowercase: "One or more lowercase letters",
						number: "One or more numbers",
						special: "One or more special characters",
						mother: "It cannot contain your mother's name",
						name: "Do not use your first or last name",
						idbrf: "Do not use your ID BRF",
						older: "Do not repeat previous passwords",
						commonWords: "Avoid everyday words at work, such as: BRF, password, sadia, perdigão",
						attention: "Attention",
					},
					errorMessage: {
						misMatch:
							"The password does not respect the minimum rules required.",
						api: "An error has occurred in our system, please try again!",
						computerized: "Password registration is an exclusive feature for employees without BRF email.",
					},
				},
				cancel: "Cancel",
				submit: "Next",
				send: "SEND",
				access: "Access",
				register: "REGISTER NEW PASSWORD",
				close: "Close",
			},
			servicenow: {
				heading: "Wait, your password is being verified and registered.",
				loading:
					"Collaborator, this registration may take a few minutes, we appreciate your patience.",

				states: {
					success: {
						heading:
							"<strong> {{name}} </strong>, your password has been registered!",
						text:
							"<strong> Important:</strong> Click on access, if you don't have a BRF e-mail, fill in with YOURID@brf.com, as follows: <br> <strong> Email: </strong> YOURID@brf.com <br> <strong> Password: </strong> your registered password, and you're done!",
					},
					error: {
						heading:
							"Registration error!",
						text:
							"We had problems to register, we apologize for the inconvenience. Please try again.<br><br><strong>Stay tuned</strong> and do not register a previously used password.",
					},
					passwordError: {
						heading: "Your password does not comply with security rules.",
						text: "Try to register again following the points of attention:<br/><ul><li>Do not repeat previous passwords;</li><li>Avoid everyday words at work, such as: BRF, password, sadia, perdigão</li></ul>"
					}
				},
			},
			loading: {
				waiting: "Fetching data, please wait ...",
			},
		},
	},
	es: {
		translation: {
			home: {
				choices: {
					register: {
						title: "¿NO TIENE BRF EMAIL?",
						/* description:
							"Registre una contraseña para poder acceder al Portal de personas.",
						obs: "* El registro es solo para empleados que no tienen un correo electrónico BRF. Si tiene correo electrónico, use los otros canales de servicio", */
						link: "REGISTRARSE O CAMBIAR SU CONTRASEÑA",
					},
					login: {
						title: "¿YA HA REGISTRADO SU CONTRASEÑA O YA TIENE BRF EMAIL?",
						/* description:
							"<strong>Importante:</strong> Haga clic en acceso, si no tiene un correo electrónico BRF, complete SUAID@brf.com, de la siguiente manera:<br><strong>Email:</strong> SEUID@brf.com <br><strong>Contraseña:</strong> su contraseña registrada, ¡y ya está!", */
						description:
							"<strong>Información para empleados sin correo electrónico BRF: </strong><br>Si no tiene un correo electrónico de BRF, en la siguiente pantalla complete el campo de correo electrónico de la siguiente manera: <br><br><b>Email:</b> SUAID@brf.com",
						link: "INICIAR SESIÓN",
					},
				},
			},
			form: {
				idBrf: {
					heading: "¿CUÁL ES SU ID DE BRF?",
					idBrf: {
						label: "¿Cuál es tu ID de BRF?",
						placeholder: "ID BRF",
						required: "Ingrese su ID de BRF",
						maxDigits: "Su identificación debe contener al máximo 6 dígitos",
					},
				},
				profile: {
					heading:
						"Necesitamos validar alguna información",
					docNumber: {
						placeholder: "CPF",
						required: "Ingrese su CPF",
						invalid: "Número de seguro social no válido",
					},
					birthDate: {
						placeholder: "Fecha de nacimiento (DD/MM/YYYY)",
						required: "Ingrese su fecha de nacimiento",
						invalid: "Fecha de nacimiento invalida",
					},
					startDate: {
						placeholder: "Fecha de admisión (DD/MM/YYYY)",
						required: "Ingresse su fecha de admisión",
						invalid: "Fecha de admisión no válida",
					},
					errorMessage: "Datos no válidos, verifique los campos y vuelva a intentarlo",
					// errorMessage: {
					// 	userNotFound: "ID de BRF incorrecta o inexistente",
					// 	maxDigits: "Su identificación debe contener un máximo de 6 dígitos",
					// 	docNumber: "Número de seguro social no válido",
					// 	startDate: "La fecha de ingreso no coincide",
					// 	birthDate: "La fecha de nacimiento no coincide.",
					// },
				},
				password: {
					heading: "¡Ingrese su contraseña y guárdela de manera segura!",
					password: {
						placeholder: "**************************",
						required: "Su contraseña debe cumplir con las reglas.",
					},
					rules: {
						heading: "Su contraseña debe cumplir con las siguientes reglas:",
						minLen: "10 o más personajes",
						uppercase: "Una o más letras mayúsculas",
						lowercase: "Una o más letras minúsculas",
						number: "Uno o mas numeros",
						special: "Uno o mas personajes especiales",
						mother: "No puede contener el nombre de tu madre",
						name: "No use tu nombre o apellido",
						idbrf: "No use tu ID BRF",
						older: "No use contraseñas usadas anteriormente",
						commonWords: "Evite palabras cotidianas en el trabajo, como: BRF, contraseña, Sadia, Perdigão",
						attention: "Atención",
					},
					errorMessage: {
						misMatch: "La contraseña no respeta las reglas mínimas requeridas.",
						api:
							"Se ha producido un error en nuestro sistema, ¡inténtalo de nuevo!",
						computerized: "El registro de contraseña es una función exclusiva para empleados sin correo electrónico de BRF.",
					},
				},
				cancel: "Cancelar",
				submit: "Seguir",
				send: "ENVIAR",
				access: "Acceso",
				register: "REGISTRAR NUEVA CONTRASEÑA",
				close: "Cerca",
			},
			servicenow: {
				heading: "Espere, su contraseña está siendo verificada y registrada.",
				loading:
					"Colaborador, este registro puede tomarse unos minutos, le agradecemos su paciencia.",

				states: {
					success: {
						heading:
							"<strong> {{name}} </strong>, ¡su contraseña ha sido registrada!",
						text:
							"<strong>Importante:</strong> Haga clic en acceso, si no tiene un correo electrónico BRF, complete SUAID@brf.com, de la siguiente manera:<br><strong>Email:</strong> SUAID@brf.com <br><strong>Contraseña:</strong> su contraseña registrada, ¡y ya está!",
					},
					error: {
						heading:
							"¡Error de registro!",
						text:
							"Tuvimos problemas para hacer la catástrofe, pedimos disculpas por las molestias. Inténtalo de nuevo.<br><br><strong>Manténganse al tanto </strong> y no registre una contraseña utilizada anteriormente.",
					},
					passwordError: {
						heading: "Su contraseña no cumple con las reglas de seguridad.",
						text: "Intente registrar la contraseña nuevamente siguiendo los puntos de atención: <br><ul><li>No repita contraseñas anteriores;</li><li>Evite palabras cotidianas en el trabajo, como: BRF, contraseña, Sadia, Perdigão. </li> </ul>"
					}
				},
			},
			loading: {
				waiting: "Obteniendo datos, espere ...",
			},
		},
	},
	tr: {
		translation: {
			home: {
				choices: {
					register: {
						title: "BRF e-mailiniz yok mu?",
						/* description:
							"Cadastre uma senha para poder acessar o Portal de Gente.",
						obs: "* Cadastro é apenas para colaboradores que não possuem e-mail BRF. Caso possua e-mail, utilizar os demais canais de atendimento", */
						link: "KAYIT OLUN VEYA ŞİFRENİZİ DEĞİŞTİRİN",
					},
					login: {
						title: "Parolanız zaten kayıtlı veya BRF e-mailiniz var mı?",/*
						description:
							"<strong>Importante:</strong> Clique em acessar, caso não tenha e-mail BRF, preencha com SEUID@brf.com, da seguinte forma: <br><strong>E-mail:</strong> SEUID@brf.com <br><strong>Senha:</strong> sua senha cadastrada, e pronto!", */
						description:
							`<strong>BRF e-maili olmayan çalışanlar için bilgilendirme:</strong><br>
							BRF e-mailiniz yoksa, bir sonraki ekranda e-mail alanını aşağdaki şekilde doldurun:<br><br>
							<b>E-mail:</b>
							PersonelSicilNo@brf.com`, //turco 1
						link: "GİRİŞ",
					},
				},
			},
			form: {
				idBrf: {
					heading: "Personel sicil numaranız nedir?", //turco 1
					idBrf: {
						label: "Personel sicil numaranız nedir?", //turco 1
						placeholder: "Personel sicil no", //turco 1
						required: "Personel sicil no giriniz", //turco 2
						maxDigits: "Personel sicil no maksimum 6 haneli olabilir", //turco 2
						lenght: {
							title: "Personel sicil no maksimum 6 haneli olabilir", //turco 2
						},
						userNotFound: {
							title: "Yanlış veya var olmayan Personel sicil no", //turco 2
						},
					},
				},
				profile: {
					heading:
						"Merhaba, bazı bilgileri doğrulamamız gerekiyor.",
					docNumber: {
						placeholder: "CPF",
						required: "Required CPF",
						invalid: "Invalid CPF",
					},
					birthDate: {
						placeholder: "DOĞUM TARİHİ (DD/MM/YYYY)",
						required: "Doğum tarihinizi giriniz",
						invalid: "Geçersiz doğum tarihi",
					},
					startDate: {
						placeholder: "İŞE GİRİŞ TARİHİ (DD/MM/YYYY)",
						required: "İşe giriş tarihinizi girin",
						invalid: "Geçersiz işe giriş tarihi",
					},
					errorMessage: "Geçersiz veriler, lütfen alanları kontrol edip tekrar deneyin.",
					// errorMessage: {
					// 	userNotFound: "Yanlış veya var olmayan Personel sicil no", //turco 2
					// 	maxDigits: "Personel sicil no maksimum 6 haneli olabilir", //turco 2
					// 	docNumber: "Invalid CPF",
					// 	startDate: "İşe giriş tarihiniz eşleşmiyor",
					// 	birthDate: "Doğum tarihiniz eşleşmiyor",
					// },
				},
				password: {
					heading: "Şifrenizi girin ve güvenli bir şekilde kaydedin!",
					password: {
						placeholder: "**************************",
						required: "Şifreniz aşağıdaki kurallara uygun olmalıdır",
					},
					rules: {
						heading: "Şifreniz aşağıdaki kurallara uygun olmalıdır:",
						minLen: "10 veya daha fazla karakter",
						uppercase: "Bir veya daha fazla büyük harf",
						lowercase: "Bir veya daha fazla küçük harf",
						number: "Bir veya daha fazla sayı",
						special: "Bir veya daha fazla özel karakter. Ör: (@ *!%;:.)",
						mother: "Annenizin adını içeremez",
						name: "İsminizi kullanmayın",
						idbrf: "Personel sicil no kullanmayın", //turco 1
						older: "Önceki şifreleri tekrar etmeyin",
						commonWords: "Şifrenizde BRF, şifre, sadia, perdigão kelimelerini kullanmaktan kaçının",
						attention: "Dikkat",
					},
					errorMessage: {
						misMatch: "Parola gerekli kurallara uymuyor",
						api: "Sistemimizde bir hata oluştu, lütfen tekrar deneyin!",
						computerized: "Şifre kaydı, BRF e-maili olmayan çalışanlar içindir",
					},
				},
				cancel: "İPTAL",
				submit: "DEVAM",
				send: "GÖNDER",
				access: "Giriş",
				register: "YENİ ŞİFRE KAYDET",
				close: "Kapat",
			},
			servicenow: {
				heading: "Bekleyin, şifreniz doğrulanıyor ve kaydediliyor.",
				loading:
					"Kayıt işlemi birkaç dakika alacaktır, sabrınız için teşekkür ederiz.",
				states: {
					success: {
						heading: "<strong>{{name}}</strong>, Şifreniz kaydedildi!",
						text:
							`<strong>Önemli:</strong> Erişim'e tıklayın, BRF e-mail iniz yoksa, e-mail alanını aşağıdaki gibi doldurun.<br>
							<strong>E-mail:</strong> Personelsicilno@brf.com<br>
							<strong>Şifre:</strong> Kayıtlı şifreniz, ve tamam!`, //turco 1
					},
					error: {
						heading:
							"Kayıt Hatası!",
						text:
							"Kayıt sırasında hata oluştu. Lütfen tekrar deneyin.",
					},
					passwordError: {
						heading: "Şifreniz güvenlik kurallarına uygun değil.",
						text: "Dikkat edilecek noktaları izleyerek tekrar kayıt olmayı deneyin:<br/><ul><li>Önceki şifreleri tekrar etmeyin;</li><li>Şifrenizde BRF, şifre, sadia, perdigão kelimelerini kullanmaktan kaçının.</li></ul>"
					}
				}
			},
		},
		loading: {
			waiting: "Veriler alınıyor, lütfen bekleyiniz ...",

		},
	},
	ar: {
		translation: {
			home: {
				choices: {
					register: {
						title: `هل أنت لا تملك بريد "بي آر إف" الإلكتروني؟`,
						/* description:
							"Cadastre uma senha para poder acessar o Portal de Gente.",
						obs: "* Cadastro é apenas para colaboradores que não possuem e-mail BRF. Caso possua e-mail, utilizar os demais canais de atendimento", */
						link: `سجّل أو أعد تعيين كلمة المرور`,
					},
					login: {
						title: `هل تم تسجيل كلمة المرور مسبقاً ، أو هل يوجد لديك بريد "بي آر إف" الإلكتروني؟`,
						/*
						description:
							"<strong>Importante:</strong> Clique em acessar, caso não tenha e-mail BRF, preencha com SEUID@brf.com, da seguinte forma: <br><strong>E-mail:</strong> SEUID@brf.com <br><strong>Senha:</strong> sua senha cadastrada, e pronto!", */
						description:
							`معلومات للموظفين بدون بريد ""بي آر إف"" الإلكتروني:<br>
							إذا لم تملك بريد ""بي آر إف"" الإلكتروني ، قم بتعبئة حقل البريد الإلكتروني في الصفحة التالية كالآتي:<br>
							البريد الإلكتروني: [رقمك الوظيفي]@brf.com`,
						// "<strong>Informação para os colaboradores sem e-mail BRF: </strong><br>Caso não tenha e-mail BRF, na próxima tela preencha o campo de e-mail da seguinte forma: <br><br><b>E-mail:</b> SEUID@brf.com",
						link: "دخول",
					},
				},
			},
			form: {
				idBrf: {
					heading: "ما هو رقمك الوظيفي؟",
					idBrf: {
						label: "ما هو رقمك الوظيفي؟",
						placeholder: "الرقم الوظيفي",
						required: `أدخل رقمك الوظيفي`,
						maxDigits: `يجب ألّا يتعدى رقمك الوظيفي 6 خانات`,
						lenght: {
							title: `يجب ألّا يتعدى رقمك الوظيفي 6 خانات`,
						},
						userNotFound: {
							title: `الرقم الوظيفي خاطئ أو غير موجود`,
						},
					},
				},
				profile: {
					heading:
						"مرحباً ، نود التأكد من بعض المعلومات للمتابعة.",
					docNumber: {
						placeholder: "CPF",
						required: "Required CPF",
						invalid: "Invalid CPF",
					},
					birthDate: {
						placeholder: "تاريخ الميلاد (يوم / شهر / سنة)",
						required: `أدخل تاريخ الميلاد`,
						invalid: `تاريخ الميلاد غير صحيح`,
					},
					startDate: {
						placeholder: "تاريخ الإلتحاق (يوم / شهر / سنة)",
						required: `أدخل تاريخ الإلتحاق`,
						invalid: `تاريخ الإلتحاق غير صحيح`,
					},
					errorMessage: "بيانات غير صحيحة ، يرجى التحقق من الحقول والمحاولة مرة أخرى.",
					// errorMessage: {
					// 	userNotFound: `الرقم الوظيفي خاطئ أو غير موجود`,
					// 	maxDigits: `يجب ألّا يتعدى رقمك الوظيفي 6 خانات`,
					// 	docNumber: "Invalid CPF",
					// 	startDate: `تاريخ الإلتحاق غير متطابق`,
					// 	birthDate: `تاريخ الميلاد غير متطابق`,
					// },
				},
				password: {
					heading: "الرجاء إدخال كلمة المرور  وحفظها في مكان آمن!",
					password: {
						placeholder: "**************************",
						required: "كلمة السر يجب أن تتبع الشروط التالية",
					},
					rules: {
						heading: "يجب أن تتّبع كلمة المرور الشروط التالية:",
						minLen: "10 خانات أو أكثر",
						uppercase: "حرف إنجليزي كبير أو أكثر",
						lowercase: "حرف إنجليزي صغير أو أكثر",
						number: "رقم أو أكثر",
						special: "رمز خاص (مثل: @*!%;.) أو أكثر",
						mother: "كلمة المرور يجب أن تكون باللغة الإنجليزية، وأن لا تحتوي على إسم والدتك",
						name: "لا تستخدم إسمك",
						idbrf: `لا تستخدم رقم "بي آر إف" الوظيفي`,
						older: "لا تستخدم كلمات المرور السابقة.",
						commonWords: "تجنب الكلمات اليومية الشائعة مثل: BRF, password, sadia, perdigão.",
						attention: "تنبيه:",
					},
					errorMessage: {
						misMatch: `كلمة المرور المدخلة لا تتوافق مع أدنى الشروط المطلوبة.`,
						api: `حدث خطأ في النظام، الرجاء المحاولة مرة أخرى!`,
						computerized: `خاصية التسجيل حصرية فقط للموظفين بدون بريد "بي آر إف" الإلكتروني.`,
					},
				},
				cancel: "إلغاء",
				submit: "متابعة",
				send: "إرسال",
				access: `أدخل`,
				register: "تسجيل كلمة مرور جديدة",
				close: `أغلق`,
			},
			servicenow: {
				heading: "الرجاء الإنتظار، كلمة السر الجديدة تحت التدقيق والتسجيل.",
				loading:
					"عزيزنا، عملية التسجيل قد تأخذ بضع دقائق، نقدر لك إنتظارك.",
				states: {
					success: {
						heading: "<strong>{{name}}</strong>, لقد تم تسجيل كلمة السر الجديدة بنجاح!",
						text:
							`"هام: إضغط على ""دخول""، إذا لم يكن لديك بريد ""بي آر إف"" إلكتروني، قم بتعبئة خانة البريد على النحو التالي: <br>
							البريد الإلكتروني: [رقمك الوظيفي]@brf.com <br>
							كلمة السر: كلمة السر المسجلة
							وهكذا تم الإنتهاء!"`,
						// "<strong>Importante:</strong> Clique em acessar, caso não tenha e-mail BRF, preencha com SEUID@brf.com, da seguinte forma: <br><strong>E-mail:</strong> SEUID@brf.com <br><strong>Senha:</strong> sua senha cadastrada, e pronto!",
					},
					error: {
						heading:
							`خطأ في التسجيل`,
						text:
							"لقد واجهتنا مشكلة في التسجيل، نعتذر على الإزعاج. الرجاء المحاولة مرة أخرى.",
					},
					passwordError: {
						heading: "كلمة السر المدخلة لا تتوافق مع قواعد الأمن.",
						text: "أعد إدخال كلمة المرور مع الأخذ بعين الإعتبار النقاط التالية:<br/><ul><li>لا تستخدم كلمات المرور السابقة.</li><li>تجنب الكلمات اليومية الشائعة مثل: <br>BRF, password, sadia, perdigão</li></ul>"
					}
				},
			},
			loading: {
				waiting: "...يتم تحضير البيانات، الرجاء الإنتظار",
			},
		},
	},
};

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		detection: {
			order: ["navigator"],
		},
		fallbackLng: ["pt", "en", "es", "tr", "ar"],
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
