import React, { InputHTMLAttributes, useState, useCallback, useRef } from "react";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { FiEye, FiEyeOff, FiCalendar } from 'react-icons/fi';
import 'react-datepicker/dist/react-datepicker.css';
import './input.scss'
import './rtl.scss'

import { ptBR, enUS, tr, arSA, es } from 'date-fns/locale';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	appearance?: string;
	container?: string;
	autoComplete?: string;
	error?: string;
	eye?: boolean;
	isDate?: boolean;
	language?: string;
	readonly?: boolean;
}

const BaseInput = React.forwardRef<HTMLInputElement,InputProps>(({ appearance, disabled,autoComplete, container, error, type, eye, isDate = false, language, ...rest }, ref) => {
	const [Eye, setEye] = useState(true);
	const changeEye = useCallback(() => {
		setEye(!Eye)
	}, [Eye])

	if (isDate) {
		const [dataPicker, setDataPicker] = useState(null);
		const refDatePicker = useRef(null);
		const containerRef = useRef(null);
		const handleChangeDate = useCallback((data: Date) => {
			const [year, month ] = data.toISOString().split('T')[0].split('-');
			const dayLocalTime = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate();
			setDataPicker(data);
			containerRef.current.querySelector('input').value = `${dayLocalTime}/${month}/${year}`;

		}, []);
		if (language) {
			registerLocale('ar', arSA);
			registerLocale('pt', ptBR);
			registerLocale('en', enUS);
			registerLocale('es', es);
			registerLocale('tr', tr);
			if (language.includes('ar')) {
				language = 'ar';
			}
			if (language.includes('pt')) {
				language = 'pt';
			}
			if (language.includes('en')) {
				language = 'en';
			}
			if (language.includes('tr')) {
				language = 'tr';
			}
			if (language.includes('es')) {
				language = 'es';
			}
		}

		return (
			<div className={`${container} ${eye && "-input_eye"}`} ref={containerRef}>
				{error &&
					<div>
						<div className="form_error">{error}</div>
						<div className="form_error-icon"></div>
					</div>
				}
				<input
					{...rest}
					type={eye ? (Eye ? 'password' : 'text') : type}
					ref={ref}
					autoComplete={autoComplete}
					className={`${appearance} ${error && "-error"}`}
				/>
				<ReactDatePicker
					onChange={handleChangeDate}
					selected={dataPicker}
					dateFormat="dd/MM/yyyy"
					locale={language}
					ref={refDatePicker}
					showYearDropdown
				/>
				<FiCalendar className="calendar" size={24} onClick={() => {
					refDatePicker.current.input.focus();
				}} />

				{eye && <span onClick={changeEye}>{Eye ? <FiEye /> : <FiEyeOff />}</span>}
			</div>
		)
	}
	return (
		<div className={`${container} ${eye && "-input_eye"}`}>
			{error &&
				<div>
					<div className="form_error">{error}</div>
					<div className="form_error-icon"></div>
				</div>
			}
			<input
				{...rest}
				readOnly ={disabled}
				type={eye ? (Eye ? 'password' : 'text') : type}
				ref={ref}
				autoComplete={autoComplete}
				className={`${appearance} ${error && "-error"} ${disabled && "-disabled"}`}
			/>

			{eye && <span onClick={changeEye}>{Eye ? <FiEye /> : <FiEyeOff />}</span>}
		</div>
	)
})

export default BaseInput;
