import axios from "axios";
import { kongConfig } from "../routes";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json", ...kongConfig },
});

API.interceptors.request.use(
  (req) => req,
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (res) => res,
  (error) => {
    return Promise.reject(error);
  }
);

export { API };
