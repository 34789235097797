import "./states.scss";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../locale/i18next";

import {
  ROUTE_IDBRF,
  ROUTE_ACCESS,
  ROUTE_HOME,
  API_CACHE,
} from "../../routes/";

import Heading from "../../components/heading/";
import Button from "../../components/button/";
import Paragraph from "../../components/paragraph/";
import { API } from "../../actions/api";
import { getKongToken } from "../../actions";

interface RootState {
  employeeId: string;
  name: string;
  ritmCode: string;
  status: number;
}

const selectState = (state: RootState) => state;

const StateCall = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const state = useSelector(selectState);
  debugger;
  let status = "";
  //const status = (state.status === 1 ? "success" : "error");
  switch (state.status) {
    case 1:
      status = "success";
      break;
    case 5:
      status = "passwordError";
      break;
    default:
      status = "error";
      break;
  }

  if (!state.employeeId && !state.ritmCode) {
    history.push(ROUTE_HOME);
  }

  const handleClick = useCallback(
    async (route) => {
      const { success, token } = await getKongToken();
      if (success) {
        await API.delete(`${API_CACHE}${state.employeeId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        history.push(route);
      }
    },
    [history, state.employeeId]
  );

  return (
    <div className="container -row -full_height">
      <div className={`states -${status}`}>
        <Heading
          title={t(`servicenow.states.${status}.heading`, { name: state.name })}
          modifiers={`-${status}`}
        />

        <Paragraph
          text={t(`servicenow.states.${status}.text`)}
          modifiers={`-${status}`}
        />

        <Button
          type="button"
          appearance={`button -default -${status} -margin15`}
          container="form_group -grow0 -order0"
          label={t("form.register")}
          onClick={(e) => handleClick(ROUTE_IDBRF)}
        />
        {status === `success` ? (
          <div className="form_group -grow0 -order0">
            <button
              onClick={(e) => {
                handleClick(ROUTE_ACCESS);
              }}
              className="button -submit -margin15"
            >
              {t<string>("form.access")}
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default StateCall;
