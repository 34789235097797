import './navbar.scss';
import './rtl.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../../locale/i18next";

const Profile = () => {
    const { i18n } = useTranslation();
    const { language } = i18n;

    const [lng, updateLanguage] = useState(language);

    const setLanguage = (i) => {

        document.getElementById('root').dir = "LTR"
        document.body.classList.remove('is_rtl')
        if (i === `ar`) {
            document.body.classList.add('is_rtl')
            document.getElementById('root').dir = "RTL"

        }
        updateLanguage(i)
        i18n.changeLanguage(i)
    }

    return (
        <div className="navbar">
            <Link to="/" className="navbar_logo"></Link>

            <ul className="navbar_language">

                <li className="navbar_language-item">
                    <Link to="#" className={`navbar_language-link ${lng === 'pt' ? '-selected' : ''}`} onClick={(e) => setLanguage("pt")}>PT</Link>
                </li>
                <li className="navbar_language-item">
                    <Link to="#" className={`navbar_language-link ${lng === 'es' ? '-selected' : ''}`} onClick={(e) => setLanguage("es")}>ES</Link>
                </li>
                <li className="navbar_language-item">
                    <Link to="#" className={`navbar_language-link ${lng === 'en' || lng === 'en-US' ? '-selected' : ''}`} onClick={(e) => setLanguage("en")}>EN</Link>
                </li>
                <li className="navbar_language-item">
                    <Link to="#" className={`navbar_language-link ${lng === 'tr' ? '-selected' : ''}`} onClick={(e) => setLanguage("tr")}>TR</Link>
                </li>
                <li className="navbar_language-item">
                    <Link to="#" className={`navbar_language-link ${lng === 'ar' ? '-selected' : ''}`} onClick={(e) => setLanguage("ar")}>AR</Link>
                </li>
            </ul>

        </div>
    );
}

export default Profile;
